import React, { Component } from "react";

class LmNotes extends Component {
    render() {
        return(
            <div>
                <h1> League Manager's Blog </h1>
                <p> ESPN has turned their LM Note editor into an abomination, and we wanted somewhere robust to put LM notes from seasons past.</p>
                <h2> UNDER CONSTRUCTION </h2>
            </div>
        );
    }
}

export default LmNotes;