import React, { Component } from "react";

class Home extends Component {
    render() {
        return(
            <div>
                <h1> Week 4, Season 7 </h1>
                <p> Welcome to our blessed league. We are a community of 9 upstanding men and 1 government-backed artifical intelligence program who like to play fantasy football with each other.</p>
                <p> Here you will find the home page of FTP Fantasy, which stands for #FuckThePetti. Named after Bryan Petti, who dominated the league in its inaugural season with a deadly 1-2 punch of LeSean McCoy and Jamaal Charles.</p>
                <p> Bards sing the tale of that fantasy team to this day.</p>
                <br></br>
                <p> Check back soon for more FTP content!</p>
            </div>
        );
    }
}

export default Home;